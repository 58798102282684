import * as React from 'react';
import CompletePageContent from '../components/CompletePageContent';

const EmailVerifiedPage = () => {
  return (
    <CompletePageContent
      title={'メールアドレス認証が完了しました！'}
      message={'Qolorのアプリを確認してください。'}
    />
  );
};

export default EmailVerifiedPage;
